<template>
  <CModal
    :title="promotion.Id ? 'Cập nhật KM' : 'Thêm KM'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="12">
            <CInput
              label="Tiêu đề*"
              placeholder="Tiêu đề"
              v-model="promotion.Name"
              :add-input-classes="{
                'is-invalid': !!inValidObject['promotion.Name'],
              }"
              :invalid-feedback="inValidObject['promotion.Name']"
            />
          </CCol>
          <CCol md="8">
            <div role="group" class="form-group">
              <label>
                Số {{ promotion.IsPercent ? "%" : "tiền" }} giảm
                <CSwitch
                  color="info"
                  size="sm"
                  variant="opposite"
                  shape="pill"
                  class="position-absolute"
                  style="right:1rem;"
                  :checked.sync="promotion.IsPercent"
                />
              </label>
              <input
                type="number"
                v-if="promotion.IsPercent"
                placeholder="Số %"
                v-model="promotion.Value"
                class="form-control text-right"
                :class="{
                  'is-invalid': !!inValidObject['promotion.Value'],
                }"
              />
              <money
                v-else
                placeholder="Số tiền"
                v-model="promotion.Value"
                class="form-control text-right"
                :class="{
                  'is-invalid': !!inValidObject['promotion.Value'],
                }"
              ></money>

              <div class="invalid-feedback">
                {{ inValidObject["promotion.Value"] }}
              </div>
            </div>
          </CCol>
          <CCol md="4">
            <CSelect
              label="Trạng thái"
              placeholder="Chọn Trạng thái"
              :value.sync="promotion.Status"
              :options="
                Object.keys($const.STATUS_TEXT).map((_) => {
                  return {
                    value: _,
                    label: $const.STATUS_TEXT[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['promotion.Status'],
              }"
              :invalid-feedback="inValidObject['promotion.Status']"
            />
          </CCol>
          <CCol md="12">
            <CInput
              label="Ghi chú"
              placeholder="Ghi chú"
              v-model="promotion.Description"
              :add-input-classes="{
                'is-invalid': !!inValidObject['promotion.Description'],
              }"
              :invalid-feedback="inValidObject['promotion.Description']"
            />
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="savePromo" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>
<script>
export default {
  props: ["editing", "promoId"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      promotion: this.newPromo(),
      saving: false,
    };
  },
  watch: {
    async promoId(val) {
      await this.loadPromoInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:promoId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },
  methods: {
    newPromo() {
      return {
        Id: 0,
        Name: null,
        Description: null,
        IsPercent: false,
        Value: 0,
        Status: this.$const.STATUS.Active.toString(),
      };
    },

    async loadPromoInfo(promoId) {
      try {
        this.inValidObject = {};
        if (promoId) {
          let filters = [];
          promoId && filters.push(`Id eq ${promoId}`);
          let resp = await this.$http.get(`odata/Promotion`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Name,Description,IsPercent,Value,Status",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.promotion = {
              Id: _.Id,
              Name: _.Name,
              Description: _.Description,
              IsPercent: _.IsPercent,
              Value: _.Value,
              Status: _.Status.toString(),
            };
          }
        } else {
          this.promotion = this.newPromo();
        }
      } catch (error) {
        alert(error);
      }
    },

    async savePromo() {
      let promotion = this.promotion;
      this.inValidObject = this.checkValid(promotion);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let promoData = {
          Name: promotion.Name,
          Description: promotion.Description,
          IsPercent: promotion.IsPercent,
          Value: parseFloat(promotion.Value),
          Status: parseInt(promotion.Status),
        };
        // Misc
        if (!promotion.Id) {
          let resp = await this.$http.post(`odata/Promotion`, promoData);
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/Promotion/${promotion.Id}`,
            promoData
          );
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(promotion) {
      let inValidObject = {};

      if (!promotion.Name)
        inValidObject["promotion.Name"] = "Vui lòng nhập Tên!";
      if (!promotion.Value)
        inValidObject["promotion.Value"] = "Vui lòng nhập Số % hoặc Số tiền!";
      if (!promotion.Status)
        inValidObject["promotion.Status"] = "Vui lòng chọn Trạng thái!";

      return inValidObject;
    },
  },
};
</script>
