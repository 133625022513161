var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilMobileLandscape"}}),_vm._v(" Khuyến Mãi ")],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function (e) {
            _vm.promoId = 0;
            _vm.editing = true;
          }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm KM ")],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Trạng thái","value":_vm.promoStatus,"options":Object.keys(_vm.statusList).map(function (_) {
                return {
                  value: _,
                  label: _vm.statusList[_],
                };
              }),"horizontal":""},on:{"update:value":function($event){_vm.promoStatus=$event}}})],1)],1),_c('odata-table',{ref:"lstPromo",attrs:{"url":"odata/Promotion","filter":_vm.filter,"select":"Id,Name,Description,IsPercent,Value,Status,CreatedAt","sortBy":"CreatedAt desc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Name: {
            field: 'Name',
            display: 'Chương trình KM',
            sortable: true,
            style: 'min-width: 120px',
          },
          IsPercent: {
            field: 'IsPercent',
            display: 'KM',
            sortable: true,
            style: 'min-width: 100px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
          Description: {
            field: 'Description',
            display: 'Ghi chú',
            sortable: true,
            style: 'min-width: 150px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstPromo.page - 1) * _vm.pageSize)+".")]),_c('td',{staticClass:"text-truncate"},[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật: " + (row.Name))},on:{"click":function (e) {
                    _vm.promoId = row.Id;
                    _vm.editing = true;
                  }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "+_vm._s(row.Name)+" ")],1)]),_c('td',[_vm._v(" Giảm "+_vm._s(_vm.$func.addCommas(row.Value))+" "+_vm._s(row.IsPercent ? "%" : "VND")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .local() .format("DD/MM/YY HH:mm"))+" ")]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":row.Description}},[_vm._v(_vm._s(row.Description))])]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.STATUS_TEXT[row.Status])+" ")])],1)])})}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"promoId":_vm.promoId},on:{"update:editing":function($event){_vm.editing=$event},"update:promoId":function($event){_vm.promoId=$event},"update:promo-id":function($event){_vm.promoId=$event},"reload":function($event){return _vm.$refs.lstPromo.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }