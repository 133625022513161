<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilMobileLandscape" />
          Khuyến Mãi
        </span>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click="
            (e) => {
              promoId = 0;
              editing = true;
            }
          "
        >
          <CIcon name="cil-plus" />
          Thêm KM
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4">
            <CSelect
              label="Trạng thái"
              placeholder="Trạng thái"
              :value.sync="promoStatus"
              :options="
                Object.keys(statusList).map((_) => {
                  return {
                    value: _,
                    label: statusList[_],
                  };
                })
              "
              horizontal
            />
          </CCol>
        </CRow>
        <odata-table
          ref="lstPromo"
          url="odata/Promotion"
          :filter="filter"
          select="Id,Name,Description,IsPercent,Value,Status,CreatedAt"
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Name: {
              field: 'Name',
              display: 'Chương trình KM',
              sortable: true,
              style: 'min-width: 120px',
            },
            IsPercent: {
              field: 'IsPercent',
              display: 'KM',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
            Description: {
              field: 'Description',
              display: 'Ghi chú',
              sortable: true,
              style: 'min-width: 150px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstPromo.page - 1) * pageSize }}.</td>
              <td class="text-truncate">
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Cập nhật: ${row.Name}`"
                  @click="
                    (e) => {
                      promoId = row.Id;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />
                  {{ row.Name }}
                </a>
              </td>
              <td>
                Giảm {{ $func.addCommas(row.Value) }}
                {{ row.IsPercent ? "%" : "VND" }}
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .local()
                    .format("DD/MM/YY HH:mm")
                }}
              </td>

              <td class="text-truncate" style="max-width:200px;">
                <span :title="row.Description">{{ row.Description }}</span>
              </td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :promoId.sync="promoId"
      @reload="$refs.lstPromo.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";

export default {
  name: "Promotion",
  components: {
    odataTable,
    detail,
  },
  data() {
    return {
      editing: false,
      promoId: 0,
      pageSize: 10,
      promoStatus: this.$const.STATUS.Active.toString(),
      statusList: this.$const.STATUS_TEXT,
    };
  },
  computed: {
    filter() {
      let filters = [];

      this.promoStatus && filters.push(`Status eq ${this.promoStatus}`);

      return filters.join(" and ");
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },
  },
};
</script>
