var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.promotion.Id ? 'Cập nhật KM' : 'Thêm KM',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":""},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tiêu đề*","placeholder":"Tiêu đề","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['promotion.Name'],
            },"invalid-feedback":_vm.inValidObject['promotion.Name']},model:{value:(_vm.promotion.Name),callback:function ($$v) {_vm.$set(_vm.promotion, "Name", $$v)},expression:"promotion.Name"}})],1),_c('CCol',{attrs:{"md":"8"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Số "+_vm._s(_vm.promotion.IsPercent ? "%" : "tiền")+" giảm "),_c('CSwitch',{staticClass:"position-absolute",staticStyle:{"right":"1rem"},attrs:{"color":"info","size":"sm","variant":"opposite","shape":"pill","checked":_vm.promotion.IsPercent},on:{"update:checked":function($event){return _vm.$set(_vm.promotion, "IsPercent", $event)}}})],1),(_vm.promotion.IsPercent)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.promotion.Value),expression:"promotion.Value"}],staticClass:"form-control text-right",class:{
                'is-invalid': !!_vm.inValidObject['promotion.Value'],
              },attrs:{"type":"number","placeholder":"Số %"},domProps:{"value":(_vm.promotion.Value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.promotion, "Value", $event.target.value)}}}):_c('money',{staticClass:"form-control text-right",class:{
                'is-invalid': !!_vm.inValidObject['promotion.Value'],
              },attrs:{"placeholder":"Số tiền"},model:{value:(_vm.promotion.Value),callback:function ($$v) {_vm.$set(_vm.promotion, "Value", $$v)},expression:"promotion.Value"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["promotion.Value"])+" ")])],1)]),_c('CCol',{attrs:{"md":"4"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Chọn Trạng thái","value":_vm.promotion.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.STATUS_TEXT[_],
                };
              }),"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['promotion.Status'],
            },"invalid-feedback":_vm.inValidObject['promotion.Status']},on:{"update:value":function($event){return _vm.$set(_vm.promotion, "Status", $event)}}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Ghi chú","placeholder":"Ghi chú","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['promotion.Description'],
            },"invalid-feedback":_vm.inValidObject['promotion.Description']},model:{value:(_vm.promotion.Description),callback:function ($$v) {_vm.$set(_vm.promotion, "Description", $$v)},expression:"promotion.Description"}})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.savePromo}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }